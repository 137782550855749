import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0c5a0623"), n = n(), _popScopeId(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "globe-icon-wrapper-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalisedInput = _resolveComponent("LocalisedInput");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            class: "edit-filling"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, { class: "checkboxes" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_AppCheckbox, { name: "noSpecialRequirements" }, {
                            default: _withCtx(() => [
                                _createElementVNode("label", null, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1)
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }),
                (!_ctx.noSpecialRequirements)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("reactor-loading")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_RadioButton, { name: "reactorLoading" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("filling-rule")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_RadioButton, {
                                    name: "fillingRule",
                                    dictionary: _ctx.fillingRuleOptions,
                                    dictionaryId: "FillingRule"
                                }, null, 8, ["dictionary"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, { class: "col-xl-7" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: _ctx.model.fillingRule === _ctx.Use2080Rule
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("maximum-filling-grade-wo-baffles")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["disabled"]),
                                _createVNode(_component_InputNumber, {
                                    disabled: _ctx.model.fillingRule === _ctx.Use2080Rule,
                                    placeholder: _ctx.translate('enter-maximum-percentage'),
                                    min: 0,
                                    max: 100,
                                    name: "maxFillingGrade",
                                    mode: "decimal"
                                }, null, 8, ["disabled", "placeholder"]),
                                _createVNode(_component_FormError, { name: "maxFillingGrade" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, { class: "col-xl-7" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: _ctx.model.fillingRule === _ctx.Use2080Rule
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("minimum-filling-grade-wo-baffles")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["disabled"]),
                                _createVNode(_component_InputNumber, {
                                    disabled: _ctx.model.fillingRule === _ctx.Use2080Rule,
                                    placeholder: _ctx.translate('enter-minimum-percentage'),
                                    min: 0,
                                    max: 100,
                                    name: "minFillingGrade",
                                    mode: "decimal"
                                }, null, 8, ["disabled", "placeholder"]),
                                _createVNode(_component_FormError, { name: "minFillingGrade" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, { class: "col-xl-7" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("maximum-technical-grade")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_InputNumber, {
                                    placeholder: _ctx.translate('enter-maximum-percentage'),
                                    min: 0,
                                    max: 100,
                                    name: "maxTechnicalFillingGrade",
                                    mode: "decimal"
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "maxTechnicalFillingGrade" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, { class: "col-xl-7" }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_InputLabel, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("additional-filling-requirements")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_GlobeIcon, { size: "sm" })
                                ]),
                                _createVNode(_component_LocalisedInput, {
                                    modelValue: _ctx.model.additionalRequirements,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.additionalRequirements) = $event)),
                                    placeholder: _ctx.translate('additional-filling-requirements')
                                }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                        })
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["modelValue", "initial"]));
}
