import { HeatingMedium } from "@/app/sub-modules/temperature-requirements/types";
export const temperatureRestrictionsInitial = [
    {
        heatingMedium: HeatingMedium.Water,
        maxHeatingMediumTemperature: null,
        isEnabled: false,
        maxHeatingMediumTemperatureUnit: null,
    },
    {
        heatingMedium: HeatingMedium.Steam,
        maxHeatingMediumTemperature: null,
        isEnabled: false,
        maxHeatingMediumTemperatureUnit: null,
    },
    {
        heatingMedium: HeatingMedium.Oil,
        maxHeatingMediumTemperature: null,
        isEnabled: false,
        maxHeatingMediumTemperatureUnit: null,
    },
    {
        heatingMedium: HeatingMedium.Glycol,
        maxHeatingMediumTemperature: null,
        isEnabled: false,
        maxHeatingMediumTemperatureUnit: null,
    },
    {
        heatingMedium: HeatingMedium.Electric,
        maxHeatingMediumTemperature: null,
        isEnabled: false,
        maxHeatingMediumTemperatureUnit: null,
    },
];
