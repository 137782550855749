import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2733bd7e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "check-in-input-list" };
const _hoisted_2 = { class: "header col-4" };
const _hoisted_3 = { class: "header col-4" };
const _hoisted_4 = { class: "header col-3" };
const _hoisted_5 = { class: "column col-4" };
const _hoisted_6 = { class: "name bold" };
const _hoisted_7 = { class: "column col-4" };
const _hoisted_8 = { class: "column col-3" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EditableTemporaryListInput = _resolveComponent("EditableTemporaryListInput");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_EditableTemporaryListInput, {
            modelValue: _ctx.model,
            "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                _cache[1] || (_cache[1] = ($event) => (_ctx.model = $event))
            ],
            addBtn: _ctx.translate('check-in-widget.buttons.add-check-in'),
            emptyMsg: _ctx.translate('empty'),
            name: _ctx.translate('check-in'),
            id: _ctx.id
        }, {
            header: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("check-in-widget.columns.name")), 1),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("check-in-widget.columns.presence")), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("check-in-widget.columns.online")), 1)
            ]),
            row: _withCtx((checkIn) => [
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getText(checkIn?.name)), 1)
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getAddress(checkIn?.physicalCheckIn?.postalAddress)), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getValue(checkIn?.onlineCheckIn?.onlineCheckInRequirement, _ctx.dictionaries.onlineCheckInRequirement, "OnlineCheckInRequirement")), 1)
            ]),
            _: 1
        }, 8, ["modelValue", "addBtn", "emptyMsg", "name", "id"])
    ]));
}
