import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2d47c2a6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "d-flex flex-column edit-temperature-date-range" };
const _hoisted_2 = { class: "label" };
const _hoisted_3 = { class: "input-container" };
const _hoisted_4 = { class: "d-flex flex-column w-100" };
const _hoisted_5 = { class: "label-to" };
const _hoisted_6 = { class: "d-flex flex-column w-100" };
const _hoisted_7 = { class: "action-container" };
const _hoisted_8 = { class: "range-input-container" };
const _hoisted_9 = { class: "d-flex flex-column mask-input-container" };
const _hoisted_10 = { class: "label-to" };
const _hoisted_11 = { class: "d-flex flex-column mask-input-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_DateInputMask = _resolveComponent("DateInputMask");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        model: _ctx.model,
        initial: _ctx.initial,
        errors: _ctx.errors,
        errorsToMap: { dateFrom: ['dateFrom.day', 'dateFrom.month'], dateTo: ['dateTo.day', 'dateTo.month'] },
        onContext: _cache[2] || (_cache[2] = ($event) => (_ctx.formService = $event))
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate(_ctx.label)), 1),
                _createVNode(_component_RadioButton, {
                    modelValue: _ctx.tempUnitModel,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.tempUnitModel) = $event)),
                    dictionary: _ctx.tempTypeDictionary,
                    dictionaryId: "TemperatureUnit"
                }, null, 8, ["modelValue", "dictionary"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (dateRange, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "temperature-input-fields-container"
                    }, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_InputNumber, {
                                    class: "input-number",
                                    modelValue: dateRange.minTemperature,
                                    "onUpdate:modelValue": ($event) => ((dateRange.minTemperature) = $event),
                                    name: `${_ctx.name}[${index}].minTemperature`,
                                    placeholder: _ctx.translate('minimum-temperature'),
                                    mode: "decimal",
                                    maxFractionDigits: 1,
                                    required: _ctx.isMinTemperatureRequired(dateRange)
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "placeholder", "required"]),
                                _createVNode(_component_FormError, {
                                    name: `${_ctx.name}[${index}].minTemperature`
                                }, null, 8, ["name"])
                            ]),
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.translate("to")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_InputNumber, {
                                    class: "input-number",
                                    modelValue: dateRange.maxTemperature,
                                    "onUpdate:modelValue": ($event) => ((dateRange.maxTemperature) = $event),
                                    name: `${_ctx.name}[${index}].maxTemperature`,
                                    placeholder: _ctx.translate('maximum-temperature'),
                                    mode: "decimal",
                                    maxFractionDigits: 1,
                                    required: _ctx.isMaxTemperatureRequired(dateRange)
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "placeholder", "required"]),
                                _createVNode(_component_FormError, {
                                    name: `${_ctx.name}[${index}].maxTemperature`
                                }, null, 8, ["name"])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_AppCheckbox, {
                                type: "checkbox",
                                modelValue: dateRange.mustSetDates,
                                "onUpdate:modelValue": [($event) => ((dateRange.mustSetDates) = $event), ($event) => (_ctx.resetDateField(index))]
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", _hoisted_9, [
                                    _createVNode(_component_DateInputMask, {
                                        modelValue: dateRange.datesRange.dateFrom,
                                        "onUpdate:modelValue": ($event) => ((dateRange.datesRange.dateFrom) = $event),
                                        name: `${_ctx.name}[${index}].datesRange.dateFrom`,
                                        disabled: !dateRange.mustSetDates
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "disabled"]),
                                    _createVNode(_component_FormError, {
                                        name: `${_ctx.name}[${index}].datesRange.dateFrom`
                                    }, null, 8, ["name"])
                                ]),
                                _createElementVNode("div", _hoisted_10, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.translate("to")), 1)
                                ]),
                                _createElementVNode("div", _hoisted_11, [
                                    _createVNode(_component_DateInputMask, {
                                        modelValue: dateRange.datesRange.dateTo,
                                        "onUpdate:modelValue": ($event) => ((dateRange.datesRange.dateTo) = $event),
                                        name: `${_ctx.name}[${index}].datesRange.dateTo`,
                                        disabled: !dateRange.mustSetDates
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "disabled"]),
                                    _createVNode(_component_FormError, {
                                        name: `${_ctx.name}[${index}].datesRange.dateTo`
                                    }, null, 8, ["name"])
                                ])
                            ]),
                            (index === 0)
                                ? (_openBlock(), _createBlock(_component_AppButton, {
                                    key: 0,
                                    disabled: _ctx.isAddNewRowEnabled,
                                    class: "p-button p-button-secondary action-button",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.addRow()))
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_Icon, { name: "add" })
                                    ]),
                                    _: 1
                                }, 8, ["disabled"]))
                                : (_openBlock(), _createBlock(_component_AppButton, {
                                    key: 1,
                                    class: "p-button p-button-secondary action-button",
                                    onClick: ($event) => (_ctx.deleteRow(index))
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_Icon, { name: "delete" })
                                    ]),
                                    _: 2
                                }, 1032, ["onClick"]))
                        ])
                    ]));
                }), 128))
            ])
        ]),
        _: 1
    }, 8, ["model", "initial", "errors", "errorsToMap"]));
}
