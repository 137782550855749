import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_DisplayShipmentPreNotificationView = _resolveComponent("DisplayShipmentPreNotificationView");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : _createCommentVNode("", true),
        _createVNode(_component_DisplayShipmentPreNotificationView, { model: _ctx.data }, null, 8, ["model"])
    ], 64));
}
