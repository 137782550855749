import { SelectedULPFilterService } from "@/app/domains/assigned-products/services/selected-ulp-filter.service";
import { createModule } from "@/di/module";
import { SelectedSiteFilterService } from "@/app/domains/sites/services/selected-site-filter.service";
import { SiteDomainId } from "@/app/domains/sites/sites.constants";
import { GeneralInformationModule } from "@/app/modules/general-information/general-information.module";
import { UlpBasicInformationSubModule } from "@/app/sub-modules/basic-information/un-loading-point/ulp-basic-information.module";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
import CreateUnloadingPointFormComponent from "@/app/domains/unloading-points/components/create-unloading-point-form.component.vue";
import UnloadingPointsContextMenuHeaderComponent from "@/app/domains/unloading-points/components/unloading-points-context-menu-header.component.vue";
import UnloadingPointsListComponent from "@/app/domains/unloading-points/components/unloading-points-list.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { InstallationsModule } from "@/app/modules/installations/installations.module";
import { SlotBookingModule } from "@/app/modules/slot-booking/slot-booking.module";
import { AccessRestrictionsSubModule } from "@/app/sub-modules/access-restrictions/access-restrictions.module";
import { ProcessesSubModule } from "@/app/sub-modules/processes/processes.module";
import { AvailableEquipmentSubModule } from "@/app/sub-modules/available-equipment/available-equipment.module";
import { ContactDataSubModule } from "@/app/sub-modules/contact-data/contact-data.module";
import { OpeningHoursSubModule } from "@/app/sub-modules/opening-hours/opening-hours.module";
import { PostalAddressSubModule } from "@/app/sub-modules/postal-address/postal-address.module";
import { ReceivingTankSubModule } from "@/app/sub-modules/receiving-tank/receiving-tank.module";
import { RegistrationSubModule } from "@/app/sub-modules/registration/registration.module";
import { SlotBookingSubModule } from "@/app/sub-modules/slot-booking/slot-booking.module";
import { UnLoadingAreaSubModule } from "@/app/sub-modules/un-loading-area/un-loading-area.module";
import { WorkingOnTopSubModule } from "@/app/sub-modules/working-on-top/working-on-top.module";
import { AssignedProductsModule } from "@/app/domains/assigned-products/assigned-products.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { UnloadingPointAssignedProductsDomainId } from "@/app/domains/assigned-products/assigned-products.constants";
import { buildingIdEditGuard } from "@/app/domains/loading-points/guards/building-id.guard";
import { SafetyAndSecurityModule } from "@/app/modules/safety-and-security/safety-and-security.module";
import { SafetyAndSecurityModuleId } from "@/app/modules/safety-and-security/safety-and-security.module";
import { VehicleAndPPESubModule } from "@/app/sub-modules/vehicle-and-ppe/vehicle-and-ppe.module";
import CreateRequirementProfileFormComponent from "@/app/domains/requirement-profiles/components/create-requirement-profile-modal.component.vue";
import { RequirementProfileDomainId } from "@/app/domains/requirement-profiles/requirement-profile.constants";
import { ShipmentPreNotificationSubModule } from "@/app/sub-modules/shipment-pre-notification/shipment-pre-notification.module";
import { AvailableEquipmentPackedSubModule } from "@/app/sub-modules/available-equipment-packed/available-equipment-packed.module";
import { AssignedProductClustersModule } from "@/app/domains/assigned-product-clusters/assigned-product-clusters.module";
import { UnloadingPointAssignedProductClustersDomainId } from "@/app/domains/assigned-product-clusters/assigned-product-cluster.constants";
const subModuleOptions = {
    editGuards: [buildingIdEditGuard],
};
export const UnloadingPointsModule = createModule({
    components: [
        UnloadingPointsContextMenuHeaderComponent,
        CreateUnloadingPointFormComponent,
        UnloadingPointsListComponent,
        CreateRequirementProfileFormComponent,
    ],
    imports: [
        DomainModule({
            parent: SiteDomainId,
            id: UnloadingPointDomainId,
            icon: "svg/unloading_point",
            detailsIcon: "svg/unloading_point",
            lists: [
                {
                    id: "all",
                    default: true,
                    filter: SelectedSiteFilterService,
                },
            ],
            domainTranslationPrefix: "unloading",
            forBusinessPartner: true,
            baseUrl: "unloadingpoints",
            components: {
                contextMenuHeader: UnloadingPointsContextMenuHeaderComponent,
                createForm: CreateUnloadingPointFormComponent,
                table: UnloadingPointsListComponent,
            },
            crossDomainDetailsCreateModal: {
                domainId: RequirementProfileDomainId,
            },
        }),
        GeneralInformationModule(UnloadingPointDomainId),
        UlpBasicInformationSubModule(UnloadingPointDomainId),
        PostalAddressSubModule(UnloadingPointDomainId, subModuleOptions),
        AccessRestrictionsSubModule(UnloadingPointDomainId, subModuleOptions),
        RegistrationSubModule(UnloadingPointDomainId, subModuleOptions),
        OpeningHoursSubModule(UnloadingPointDomainId, subModuleOptions),
        ContactDataSubModule(UnloadingPointDomainId, subModuleOptions),
        ProcessesSubModule(UnloadingPointDomainId, subModuleOptions),
        //
        InstallationsModule(UnloadingPointDomainId),
        UnLoadingAreaSubModule(UnloadingPointDomainId),
        WorkingOnTopSubModule(UnloadingPointDomainId),
        AvailableEquipmentSubModule(UnloadingPointDomainId),
        AvailableEquipmentPackedSubModule(UnloadingPointDomainId),
        ReceivingTankSubModule(UnloadingPointDomainId),
        //
        SlotBookingModule(UnloadingPointDomainId),
        SlotBookingSubModule(UnloadingPointDomainId),
        ShipmentPreNotificationSubModule(UnloadingPointDomainId),
        //
        SafetyAndSecurityModule(UnloadingPointDomainId),
        VehicleAndPPESubModule(UnloadingPointDomainId, SafetyAndSecurityModuleId),
        //
        createModule({ imports: [AssignedProductsModule(UnloadingPointDomainId, UnloadingPointAssignedProductsDomainId)] }),
        createModule({
            imports: [AssignedProductClustersModule(UnloadingPointDomainId, UnloadingPointAssignedProductClustersDomainId)],
        }),
    ],
    providers: [SelectedULPFilterService, { token: TranslationPrefixToken, useValue: "domains.unloading-point" }],
});
