import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-58e4cb52"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-general-instructions" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-description" };
const _hoisted_5 = { class: "row" };
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6 column-description" };
const _hoisted_8 = {
    key: 0,
    class: "col-6 offset-6"
};
const _hoisted_9 = { class: "column-value" };
const _hoisted_10 = { class: "column-value" };
const _hoisted_11 = { class: "row" };
const _hoisted_12 = { class: "col-6 column-header" };
const _hoisted_13 = { class: "col-6 column-description" };
const _hoisted_14 = { class: "row" };
const _hoisted_15 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_16 = { class: "col-6 column-description" };
const _hoisted_17 = {
    key: 0,
    class: "col-6 offset-6"
};
const _hoisted_18 = { class: "column-value" };
const _hoisted_19 = { class: "row" };
const _hoisted_20 = { class: "col-6 column-header" };
const _hoisted_21 = { class: "col-6 column-description" };
const _hoisted_22 = { class: "row" };
const _hoisted_23 = { class: "col-6 column-header" };
const _hoisted_24 = { class: "col-6 column-description" };
const _hoisted_25 = { class: "row" };
const _hoisted_26 = { class: "col-6 column-header" };
const _hoisted_27 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("product-must-be-delivered-solo")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.productMustBeDeliveredSolo), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("container-held-on-site")), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.containerHeldOnSite()), 1),
            (_ctx.isContainerHeldOnSite)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("if-container-held-on-site")), 1),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.containerHeldOnSiteTimeInDays), 1)
                ]))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("latest-arrival"))), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.latestArrival), 1)
        ]),
        _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
                _createTextVNode(_toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("location-of-truck-keys"))) + " ", 1),
                (_ctx.isOtherKeyLocation)
                    ? (_openBlock(), _createBlock(_component_GlobeIcon, {
                        key: 0,
                        size: "md"
                    }))
                    : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.keyLocation), 1),
            (_ctx.isOtherKeyLocation)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.otherKeyLocation)), 1)
                ]))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("operator-presence"))), 1),
            _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.presenceAssistanceInfo("operatorPresenceDuringLoadingUnloading")), 1)
        ]),
        _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("driver-presence-required"))), 1),
            _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.presenceAssistanceInfo("driverPresenceDuringLoadingUnloading")), 1)
        ]),
        _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("driver-assistance"))), 1),
            _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.presenceAssistanceInfo("driverAssistanceDuringLoadingUnloading")), 1)
        ]),
        _createVNode(_component_DisplayAttachments, {
            attachments: _ctx.additionalRestrictionsLocalised,
            title: _ctx.translate('additional-instructions')
        }, null, 8, ["attachments", "title"])
    ]));
}
