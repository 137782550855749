import DOMPurify from "dompurify";
import { marked } from "marked";
export const parseMarkdown = (source) => {
    try {
        return DOMPurify.sanitize(marked.parse(source));
    }
    catch {
        return source;
    }
};
